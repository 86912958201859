import { IInput } from "@/components/form";

export interface IManageExhibitorFilters {
    search: IInput;
}
export const manageExhibitorFilters: IManageExhibitorFilters = {
    search: {
        id: 'event-search',
        name: 'event_search',
        placeholder: 'Search',
        value: '',
        type: 'text',
    },
};

export const manageExhibitorFiltersModel = {
    search: '',
};