












































// @ is an alias to /src
import { MutationPayload } from 'vuex';
import { IExhibitor } from '@/models/exhibitor';
import store from '@/store';
import { IExhibitorCard } from '@/partials';
import ExhibitorCard from '@/partials/ExhibitorCard.vue';
import Input from '@/components/form/input.vue';
import { computed, defineComponent } from '@vue/composition-api';
import useContext from '@/composition/context';
import { manageExhibitorFilters, manageExhibitorFiltersModel } from '.';
import { IExhibitorsParams, IExhibitorsState } from '@/store/modules/exhibitors';
import { IVisitorsParams } from '@/models/visitor';

export default defineComponent({
  components: {
    ExhibitorCard,
    Input,
  },
  data() {
    return {
      event: 0,
      exhibitors: [] as IExhibitor[],
      filters: manageExhibitorFilters,
      model: manageExhibitorFiltersModel,
      searchTimeout: 0,
    };
  },
  setup() {
    const { userAdmin, userManages, relatedExhibitors, translations } = useContext();
    const exhibitorsState = computed(() => store.getters.getExhibitorsState as IExhibitorsState);
    return {
      userAdmin,
      userManages,
      relatedExhibitors,
      translations,
      exhibitorsState,
    };
  },

  created() {
    this.event = parseInt(this.$route.params.event, 10);
    const userRolesSet = store.getters.getUserRolesSet;
    this.filters.search.onInput = this.searchExhibitors;

    if (userRolesSet) {
      this.getExhibitors();
    } else {
      const unsubscribe = store.subscribe((mutation: MutationPayload) => {
        if (mutation.type === 'setRoles') {
          this.getExhibitors();
          unsubscribe();
        }
      });
    }
  },
  methods: {
    searchExhibitors(value: string | number) {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.exhibitors = [];
        const params: IExhibitorsParams = {
          event: this.event,
          search: value as string,
          page: 1,
          page_size: 18,
          reset: true,
        };
        store.dispatch('fetchExhibitors', params);
        const unsubscribe = store.subscribe((mutation: MutationPayload) => {
          if (mutation.type === 'setExhibitors') {
            this.exhibitors = mutation.payload.list;
            unsubscribe();
          }
        });
      }, 1000);
    },

    nextPage() {
      if (this.exhibitorsState.page) {
        const params: IExhibitorsParams = {
          page: this.exhibitorsState.page + 1,
          page_size: 18,
        };

        if (this.model.search !== '') {
          params.search = this.model.search;
        }

        store.dispatch('fetchExhibitors', params);
        const unsubscribe = store.subscribe((mutation: MutationPayload) => {
          if (mutation.type === 'setExhibitors') {
            this.exhibitors = [...this.exhibitors, ...mutation.payload.list];
            unsubscribe();
          }
        });
      }
    },
    getExhibitors() {
      if (this.userAdmin || this.userManages.includes(this.event)) {
        const params: IVisitorsParams = {
          event: this.event,
          page: 1,
          page_size: 18,
        };
        store.dispatch('fetchExhibitors', params);
        const unsubscribe = store.subscribe((mutation: MutationPayload) => {
          if (mutation.type === 'setExhibitors') {
            this.exhibitors = mutation.payload.list;
            unsubscribe();
          }
        });
      } else if (this.relatedExhibitors) {
        const exhibitors = this.relatedExhibitors.filter(
          (exhibitor) => this.event === exhibitor.event,
        );
        this.exhibitors = exhibitors;
      }
    },

    cardConfig(exhibitor: IExhibitor) {
      const event = parseInt(this.$route.params.event, 10);
      const config: IExhibitorCard = {
        name: exhibitor.name,
        logo: exhibitor.logo_url,
        route: `/event/${event}/manage-exhibitor/${exhibitor.id}`,
      };
      return config;
    },
  },
});
